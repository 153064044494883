import { MatchOperator, Version } from "../../model/model"

export default interface OperatorMatcher {
  stringMatches(userValue: string, matchValue: string): boolean

  numberMatches(userValue: number, matchValue: number): boolean

  booleanMatches(userValue: boolean, matchValue: boolean): boolean

  versionMatches(userValue: Version, matchValue: Version): boolean
}

export class InMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return userValue === matchValue
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return userValue === matchValue
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return userValue === matchValue
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return userValue.isEqualTo(matchValue)
  }
}

export class ContainsMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return false
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return userValue.includes(matchValue)
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return false
  }
}

export class StartsWithMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return false
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return userValue.startsWith(matchValue)
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return false
  }
}

export class EndsWithMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return false
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return userValue.endsWith(matchValue)
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return false
  }
}

export class GreaterThanMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return userValue > matchValue
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return false
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return userValue.isGreaterThan(matchValue)
  }
}

export class GreaterThanOrEqualToMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return userValue >= matchValue
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return false
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return userValue.isGreaterThanOrEqualTo(matchValue)
  }
}

export class LessThanMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return userValue < matchValue
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return false
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return userValue.isLessThan(matchValue)
  }
}

export class LessThanOrEqualToMatcher implements OperatorMatcher {
  booleanMatches(userValue: boolean, matchValue: boolean): boolean {
    return false
  }

  numberMatches(userValue: number, matchValue: number): boolean {
    return userValue <= matchValue
  }

  stringMatches(userValue: string, matchValue: string): boolean {
    return false
  }

  versionMatches(userValue: Version, matchValue: Version): boolean {
    return userValue.isLessThanOrEqualTo(matchValue)
  }
}

export class OperatorMatcherFactory {
  private static IN_MATCHER = new InMatcher()
  private static CONTAINS_MATCHER = new ContainsMatcher()
  private static STARTS_WITH_MATCHER = new StartsWithMatcher()
  private static ENDS_WITH_MATCHER = new EndsWithMatcher()
  private static GT_MATCHER = new GreaterThanMatcher()
  private static GTE_MATCHER = new GreaterThanOrEqualToMatcher()
  private static LT_MATCHER = new LessThanMatcher()
  private static LTE_MATCHER = new LessThanOrEqualToMatcher()

  getMatcher(operator: MatchOperator): OperatorMatcher {
    switch (operator) {
      case "IN":
        return OperatorMatcherFactory.IN_MATCHER
      case "CONTAINS":
        return OperatorMatcherFactory.CONTAINS_MATCHER
      case "STARTS_WITH":
        return OperatorMatcherFactory.STARTS_WITH_MATCHER
      case "ENDS_WITH":
        return OperatorMatcherFactory.ENDS_WITH_MATCHER
      case "GT":
        return OperatorMatcherFactory.GT_MATCHER
      case "GTE":
        return OperatorMatcherFactory.GTE_MATCHER
      case "LT":
        return OperatorMatcherFactory.LT_MATCHER
      case "LTE":
        return OperatorMatcherFactory.LTE_MATCHER
    }
  }
}
